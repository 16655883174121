import Cookies from "js-cookie";
import { TenantCustomDomainAPI } from "./CustomDomainAPI";

const getHeaders = async (hostname) => {
  let subdomain = hostname?.split(".")[0];
  const customTenant = Cookies.get("custom_tenant");

  if (
    !(
      hostname?.includes("ebrainee.com") ||
      hostname?.includes("e-brainee.com") ||
      hostname?.includes("localhost")
    ) &&
    !customTenant
  ) {
    const res = await TenantCustomDomainAPI(hostname);
    console.log(res?.response);
    Cookies.set("custom_tenant", res?.response?.tenantName, {
      path: "/",
      domain: res?.response?.customDomain,
      secure: true,
      sameSite: "Strict",
    });
    return {
      "Content-Type": "application/json",
      "X-Tenant-ID": res?.response?.tenantName,
    };
  }
  if (customTenant) {
    return {
      "Content-Type": "application/json",
      "X-Tenant-ID": customTenant,
    };
  } else if (
    subdomain === "www" ||
    subdomain === "localhost" ||
    hostname.includes("www.ebrainee.com")
  ) {
    return {
      "Content-Type": "application/json",
    };
  } else {
    return {
      "Content-Type": "application/json",
      "X-Tenant-ID": subdomain,
    };
  }
};

export default getHeaders;
