const API = process.env.NEXT_PUBLIC_API_URL;

export async function TenantCustomDomainAPI(hostname) {

  const removeWWW = hostname?.replace("www.", "");
  const res = await fetch(API + `/custom-domain/${removeWWW}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });

  return res;
}
